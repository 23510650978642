import { ElMessage } from "element-plus";
import _ from "lodash";

export const noticeCacheErrorDownloadResult = _.debounce(
  (data: any) => {
    console.log("#message error:", data);
    const { version_time } = data?.data || {};
    ElMessage.warning({
      offset: 200,
      message: `当前网络环境较差，已为您加载本地备份版本${version_time}`,
      type: "warning",
    });
  },
  500,
  {
    leading: true,
    trailing: false,
  }
);
