
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  onBeforeMount,
  onUnmounted,
  toRaw,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { loadMicroApp } from "qiankun";
import { eventCenter, event } from "@evideo/frontend-utils";
import {
  getSections,
  getTeacherSections,
  getCoursewares,
  copyOfficial,
  addDownloadLog,
  addViewLog,
  addInsViewLog,
  dataEmbeddingPoint,
  getInstitutionCoursewares,
  getSchoolCoursewares,
  getTeacherInfo,
  getSchoolInstitutionInfo,
} from "@/services";
import store, { MutationNames } from "@/store/index";
import { ElMessage } from "element-plus";
import servers from "@/config/servers";
import config from "@/config/app.config";
import { message as antdMessage } from "ant-design-vue";
import ShowResource from "@/components/showResource";
import _ from "lodash";
import moment from "moment";
import { setMicroAppMap } from "@/main";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logger from "@evideo/logger";
import {
  getCacheFile,
  uploadCacheFile,
  uploadCacheFileFormData,
} from "@/services/localResource";
import { inject } from "vue";
import LocalResourceExchange from "@evideo/local-resource-exchange";
import { SocketEventMap } from "@/utils/socket-event";
import { noticeCacheErrorDownloadResult } from "@/utils/socketCommon";
import {
  checkCurrentCacheDownloading,
  subCurrentCacheDownloadNum,
} from "@/utils/localResourceParse";

export default defineComponent({
  components: {
    ShowResource,
    Swiper,
    SwiperSlide,
  },
  setup() {
    console.time("时间评估：获取教材章节时间并处理数据");
    let isAndroid = ref(true);
    let bookType = ref("");
    let firstParams = null;
    let pptFun = "";
    let tree = ref(null);
    let treeData = ref([]);
    let treeParams = ref([]);
    let treeCurrentNodeKey = ref("");
    let isEnlarge = ref(false);
    let centerDialogVisible = ref(false);
    let pptLoading = ref(true);
    let dvLeftShow = ref(true); // 左侧菜单是否展示
    let morePptShow = ref(false); // 是否为更多资源页面的ppt预览
    let fullScreen = ref(false); // 是否为全屏放映ppt
    let isOpenMorePpt = ref(false); // 是否打开更多资源中的ppt
    let firstLoading = ref(true);
    let reLoading = ref(false);
    let mySwiper = ref(null);
    let middleResourcesEight = reactive([]);
    let middleResourcesEightTxt = ref("");
    let lastResourcesEight = reactive([]);
    let lastResourcesEightTxt = ref("");
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    let noDataMsg = ref(false);
    const openMoreDailogSource = ref("outppt");
    const pptNowPlayMode = ref("preview");
    const route = useRoute();
    const router = useRouter();
    const showResourceRef = ref(null);
    let message = ref("");
    // 选中缓存到本地的章节节点数据
    let treeDownloadingData = ref({});
    let data = "";
    const defaultProps = reactive({
      children: "children",
      label: "label",
    });
    let url = ref("");
    let materialName = ref("");

    // 第一个节点的ppt
    let firstPptUrl = reactive({
      url: "",
    });
    // 选中的ppt
    let clickPptUrl = reactive({
      url: "",
    });
    // 上一个节点的ppt
    let previousPptUrl = reactive({
      url: "",
    });
    // 下一个节点的ppt
    let nextPptUrl = reactive({
      url: "",
    });
    // 保存章节列表的初始化数据
    let initializationTreeData = ref("");
    const androidUserType = ref(store.state.androidUserType);
    let resourcesEight = reactive([]);
    let resourcesEightTxt = ref("");
    let resourcesList = reactive([]);
    let currentPage = ref(1);
    let totalPage = ref(1);
    const firstLoadType = route.query.firstLoadType;
    let pptLoadTxt = ref("加载资源中...");
    // 处理教学记忆相关数据
    const materialType = ref(0);
    const memory = store.state.teachMemory.period || Array(2);
    const memoryNext = store.state.teachMemory.nextPeriod || Array(2);
    const courseWareMemroy = store.state.teachMemory.courseWare || Array(2);
    const courseWareMemroyNext =
      store.state.teachMemory.courseWareNext || Array(2);
    const materialMemory = store.state.teachMemory.material || Array(2);
    const teachMaterialBook = {
      id: route.query.bookId,
      url: route.query.url,
      name: route.query.name,
      type: route.query.teachMaterialType,
    };
    const isReturnToPPT = ref(false);
    // official:教材中心 ｜｜myCourse:我的教材
    bookType.value = route.query.teachMaterialType;
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    logger.info("isAndroid", isAndroid);
    logger.info("device", device);

    // tab栏的值
    const TabTypeMap = {
      School: "school",
      Institution: "institution",
      Platform: "platform",
    };
    const showIns = ref(false);
    // 当前tab栏
    let currentTab = ref(TabTypeMap.Platform);
    // 当前查看更多资源的节点id
    let currentMoreResourceSectionId = ref();
    // 是否有本地缓存的处理数据
    let finishedLocalCacheData = ref([]);
    // 是否启用缓存模块
    const localResourceState = ref(store.state.localResourceState);
    const schoolId = ref();
    // 缓存到本地之后接收缓存服务发过来的消息
    const updateTreeData = []; // 将treedata数组平铺开，用与修改节点的状态

    const isHighVersion = ref(false); // 用来判断版本

    const sourceOrigin = ref("platform"); // 资源来源，默认platform

    const socket = inject("socket");
    const downloadStatus = (res) => {
      updateTreeData.length = 0;
      console.log("#message: ", res);
      if (res.data.details[0].mode === "tree") {
        handleLocalUpdateTreeData(treeData.value);
        console.log("2", updateTreeData);
        updateTreeData.forEach((item) => {
          if (
            decodeURI(item.pptUrl) === res.data.details[0].cloud_url &&
            !isHighVersion.value
          ) {
            item.localState = "success";
            item.pptUrl = res.data.details[0].file_url;
          }
          // 新版本兼容
          if (
            item?.id === res.data.details[0]?.cloud_id &&
            isHighVersion.value
          ) {
            if (res.data.details[0].status === 0) {
              item.localState = "success";
              item.pptUrl = res.data.details[0].file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = 0;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
        });
      } else if (res.data.details[0].mode === "moreEight") {
        console.log("resourcesEight.value", resourcesEight);
        resourcesEight.forEach((item) => {
          if (
            decodeURI(item.url) === res.data.details[0].cloud_url &&
            !isHighVersion.value
          ) {
            item.localState = "success";
            item.url = res.data.details[0].file_url;
          }
          if (
            item?.id === res.data.details[0]?.cloud_id &&
            item?.type?.toUpperCase() === res.data.details[0]?.type &&
            isHighVersion.value
          ) {
            if (res.data.details[0].status === 0) {
              item.localState = "success";
              item.url = res.data.details[0].file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = 0;

              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
        });
      } else if (res.data.details[0].mode === "moreMiddle") {
        console.log("resourcesEight.value", resourcesEight);
        middleResourcesEight.forEach((item) => {
          if (
            decodeURI(item.url) === res.data.details[0].cloud_url &&
            !isHighVersion.value
          ) {
            item.localState = "success";
            item.url = res.data.details[0].file_url;
          }
          if (
            item?.id === res.data.details[0]?.cloud_id &&
            item?.type?.toUpperCase() === res.data.details[0]?.type &&
            isHighVersion.value
          ) {
            if (res.data.details[0].status === 0) {
              item.localState = "success";
              item.url = res.data.details[0].file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = 0;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
        });
      } else if (res.data.details[0].mode === "moreLast") {
        console.log("resourcesEight.value", lastResourcesEight);
        lastResourcesEight.forEach((item) => {
          if (
            decodeURI(item.url) === res.data.details[0].cloud_url &&
            !isHighVersion.value
          ) {
            item.localState = "success";
            item.url = res.data.details[0].file_url;
          }
          if (
            item?.id === res.data.details[0]?.cloud_id &&
            item?.type?.toUpperCase() === res.data.details[0]?.type &&
            isHighVersion.value
          ) {
            if (res.data.details[0].status === 0) {
              item.localState = "success";
              item.url = res.data.details[0].file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = 0;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
        });
      }
    };
    const downloadProgress = (res) => {
      console.log("#message progress: ", res);
      if (!isHighVersion.value) {
        return;
      }
      const { mode, complete_file_num, total_file_num, cloud_id, type } =
        res.data || {};
      const currentProgress = total_file_num
        ? Math.floor((complete_file_num / total_file_num) * 100)
        : 0;
      if (mode === "tree") {
        handleLocalUpdateTreeData(treeData.value);
        updateTreeData.forEach((item) => {
          // 新版本兼容
          if (item?.id === cloud_id) {
            item.localDownloadProgress = currentProgress;
          }
        });
      } else if (mode === "moreEight") {
        resourcesEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      } else if (mode === "moreMiddle") {
        middleResourcesEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      } else if (mode === "moreLast") {
        console.log("resourcesEight.value", lastResourcesEight);
        lastResourcesEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      }
    };
    socket.on(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
    socket.on(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
    socket.on(
      SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
      noticeCacheErrorDownloadResult
    );
    // 更多资源中页数变化的时候将当前页数保存到全局变量
    watch(
      () => currentPage.value,
      (newVal) => {
        store.commit(MutationNames.CHANGE_MORERESOURCETABPAGE, newVal);
        logger.info("更多资源的页数进行了全局变量的保存", newVal);
      }
    );
    // 保存处理向缓存服务请求是否有本地资源的数据
    const LocalCacheData = ref([]);
    // 处理该教材需要确认的资源数据
    const handleLocalCacheData = (data) => {
      data.forEach((el) => {
        const newNode = {
          id: el.id,
          mode: bookType.value,
        };
        if (el.type === 0) {
          handleLocalCacheData(el.children || []);
        }
        if (el.type === 1) {
          newNode["tree_id"] = el.section_resource?.id;
          newNode["md5"] = el.section_resource?.md5;
          newNode["version_time"] = el.section_resource?.updated_at;
          newNode["url"] = el.section_resource?.url;
        }
        if (newNode.tree_id > 0) {
          LocalCacheData.value.push(JSON.parse(JSON.stringify(newNode)));
        }
      });
      return LocalCacheData.value;
    };
    // 兼容新缓存接口对应处理该教材需要确认的资源数据
    const handleHighLocalCacheData = (data) => {
      data.forEach((el) => {
        const newNode = {
          cloud_id: el.id,
        };
        if (el.type === 0) {
          handleHighLocalCacheData(el.children || []);
        }
        if (el.type === 1) {
          newNode["tree_id"] = el.section_resource?.id;
          newNode["version_time"] = el.section_resource?.updated_at;
          newNode["type"] = el.section_resource?.type.toUpperCase();
          newNode["source"] = "official";
        }
        if (newNode.tree_id > 0) {
          const { tree_id, ...rest } = newNode;
          LocalCacheData.value.push(JSON.parse(JSON.stringify(rest)));
        }
      });
      return { items: LocalCacheData.value };
    };
    // 向缓存服务确认资源是否在本地已存在
    const isLocalCacheData = (data) => {
      return getCacheFile(JSON.stringify(data));
    };
    // 下载资源到本地,修改treeData章节数据
    const handleLocalUpdateTreeData = (data, updateObj) => {
      const params = [];
      data.forEach((el) => {
        let newNode = {
          id: el.id,
          label: el.label,
          type: el.type,
          pptUrl: el.pptUrl,
          courseware_id: el.courseware_id,
          courseware_count: el.courseware_count,
          localState: el.localState,
          localDownloadProgress: el.localDownloadProgress,
          md5: el.md5,
          version_time: el.version_time,
        };
        if (newNode.type === 0) {
          updateTreeData.push(el);
          newNode.children = handleLocalUpdateTreeData(
            el.children || [],
            updateObj
          );
        }
        if (newNode.type === 1) {
          updateTreeData.push(el);
          // 将数据和本地是否有资源的数据进行比较，重新处理数据
          if (newNode.id === treeDownloadingData.value.id) {
            // newNode["localState"] = "downloading";
            // newNode["localDownloadProgress"] = 0;
            if (updateObj) {
              for (let key in updateObj) {
                newNode[key] = updateObj[key];
              }
            } else {
              newNode["localState"] = "downloading";
            }
          }
        }
        params.push(newNode);
      });
      return params;
    };
    // 下载资源到本地,修改更多资源的数据
    const handleLocalUpdateMoreData = (id, type, updateObj) => {
      let data = [];
      if (type === "eight") {
        data = resourcesEight;
      } else if (type === "last") {
        data = lastResourcesEight;
      } else {
        data = middleResourcesEight;
      }
      let params = [];
      data.forEach((el) => {
        let newNode = el;
        if (newNode.id === id) {
          // newNode["localState"] = "downloading";
          // newNode["localDownloadProgress"] = 0;
          if (updateObj) {
            for (let key in updateObj) {
              newNode[key] = updateObj[key];
            }
          }
        }
        // console.log("newNode前", newNode);
        newNode = JSON.parse(JSON.stringify(newNode));
        // console.log("newNode后", newNode);
        params.push(newNode);
      });
      return params;
    };
    // tree部分，下载解析失败或create失败，将loading图标置为未下载状态
    const handleChangeLoading = (data, originData) => {
      const params = [];
      data.forEach((el) => {
        if (el.type === 0) {
          el.children = handleChangeLoading(el.children || [], originData);
        }
        if (el.type === 1) {
          if (el.id === originData.id) {
            el["localState"] = "error";
            el["localDownloadProgress"] = undefined;
          }
        }
        params.push(el);
      });
      return params;
    };
    // 更多部分，下载解析失败或create失败，将loading图标置为未下载状态
    const handleChangeLoadingOther = (data, originData) => {
      data.forEach((item) => {
        if (item.id === originData.id) {
          item["localState"] = "error";
          item["localDownloadProgress"] = undefined;
        }
      });
      return data;
    };
    const handleChooseOrigin = (type, data) => {
      if (type === "tree") {
        treeData.value = handleChangeLoading(treeData.value, data);
      } else if (type === "moreEight") {
        resourcesEight.value = handleChangeLoadingOther(resourcesEight, data);
      } else if (type === "moreLast") {
        lastResourcesEight.value = handleChangeLoadingOther(
          lastResourcesEight,
          data
        );
      } else {
        middleResourcesEight = handleChangeLoadingOther(
          middleResourcesEight,
          data
        );
      }
      const selected = _.cloneDeep(store.state.pptTreeId);
      setTimeout(() => {
        // 设置当前选中项
        store.state.pptTreeId && tree.value.setCurrentKey(selected);
      }, 0);
    };

    const handleUpdateData = (type, data, updateObj) => {
      switch (type) {
        case "tree": {
          treeDownloadingData.value = data;
          console.log("修改章节数据");
          // treeDownloadingData.value = data;
          const newTreeData = handleLocalUpdateTreeData(
            treeData.value,
            updateObj
          );
          console.log("newTreeData", newTreeData);
          // 把新的树的节点更新
          treeData.value = newTreeData;
          // 展开树的节点
          if (store.state.pptTreeId) {
            treeParams.value.pop();
            treeParams.value.push(store.state.pptTreeId);
          }
          console.log(
            "treeCurrentNodeKey2",
            treeCurrentNodeKey.value,
            treeParams.value
          );
          console.log(
            "treeCurrentNodeKey2 store.state.pptTreeId",
            store.state.pptTreeId
          );
          const selected = _.cloneDeep(treeParams.value[0]);
          setTimeout(() => {
            // 设置当前选中项
            store.state.pptTreeId && tree.value.setCurrentKey(selected);
          }, 0);
          break;
        }
        case "moreEight": {
          const newData = handleLocalUpdateMoreData(
            data.id,
            "eight",
            updateObj
          );
          console.log("修改更多第一页数据", newData);
          resourcesEight.length = 0;
          resourcesEight.push(...newData);
          break;
        }
        case "moreLast": {
          const newData = handleLocalUpdateMoreData(data.id, "last", updateObj);
          console.log("修改更多最后页数据", newData);
          lastResourcesEight.length = 0;
          lastResourcesEight.push(...newData);
          break;
        }
        default: {
          const newData = handleLocalUpdateMoreData(
            data.id,
            "middle",
            updateObj
          );
          console.log("修改更多中间页数据", newData);
          middleResourcesEight.length = 0;
          middleResourcesEight.push(...newData);
          break;
        }
      }
    };

    // 处理该教材需要确认的资源数据
    const handleLocalResource = (data, type) => {
      if (isHighVersion.value && !checkCurrentCacheDownloading()) {
        return;
      }
      console.log("下载到本地", data, type, treeData.value);
      handleUpdateData(type, data, { localState: "downloading" });

      if (isHighVersion.value) {
        const currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        const testArrays = {
          type: type === "tree" ? "ppt" : data.type,
          url: type === "tree" ? data.pptUrl : data.url,
          name: data.name || data.label,
          version_time: data.version_time || data.updated_at || currentTime,
          source: sourceUtil(sourceOrigin.value),
          cloud_id: data?.id,
        };

        window.requestIdleCallback(() => {
          console.log("开始缓存资源解析下载");
          LocalResourceExchange.resourceParse(testArrays)
            .then((res) => {
              let formData = new FormData();
              formData.append("version_time", testArrays.version_time);
              formData.append("mode", type); // 参考socket里的mode形式
              formData.append("cloud_id", data.id);
              if (type === "tree" || data.type === "ppt") {
                formData.append("resource_file", res);
                formData.append("name", (data.name || data.label) + ".json");
              } else {
                formData.append("resource_file_url", data.url);
                formData.append(
                  "name",
                  (data.name || data.label) +
                    "." +
                    data.url.substring(data.url.lastIndexOf(".") + 1)
                );
              }
              formData.append(
                "type",
                type === "tree" ? "PPT" : data.type.toUpperCase()
              );
              formData.append("source", sourceUtil(sourceOrigin.value));
              // 当前是否还停留在教材页面，是则创建下载，否则不创建下载
              if (router?.currentRoute.value?.path?.includes("/materialPpt")) {
                uploadCacheFileFormData(formData)
                  .then(() => {
                    handleUpdateData(type, data, { localDownloadProgress: 0 });
                    console.log("创建缓存资源下载成功");
                  })
                  .catch(() => {
                    handleChooseOrigin(type, data);
                    ElMessage.error({
                      offset: 200,
                      message: "资源下载失败",
                      type: "error",
                    });
                  });
              }
            })
            .catch((err) => {
              handleChooseOrigin(type, data);
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            })
            .finally(() => {
              subCurrentCacheDownloadNum();
            });
        });
      } else {
        let params = [
          {
            url: type === "tree" ? data.pptUrl : data.url,
            version_time: type === "tree" ? data.version_time : data.updated_at,
            md5: data.md5,
            id: data.id,
            name: type === "tree" ? data.label : data.name,
            mode: type,
          },
        ];
        console.log("params", params);
        uploadCacheFile(params);
      }
    };
    // 不触发下载资源到本地
    const handleLocalResourceStop = () => {
      console.log("不触发下载资源到本地到本地");
    };
    // 根据type类型返回对应的source
    const sourceUtil = (type) => {
      if (type === "moreMiddle" || type === "institution") {
        return "ins";
      } else if (type === "moreLast" || type === "school") {
        return "school";
      } else {
        return "official";
      }
    };
    const handleCloudId = (data) => {
      if (data.length === 0) {
        return data;
      }
      const res = data.map((item) => {
        const { cloud_id, ...rest } = item;
        return { id: cloud_id, ...rest };
      });
      return res;
    };
    onBeforeMount(() => {
      getTeacherData();
    });

    onMounted(async () => {
      console.time("初始化数据");
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        logger.info("ipcRenderer", ipcRenderer);
        ipcRenderer.on("pptGoBack", (event, message) => {
          // 接收electron原生返回的信息
          logger.info("我接收到的electron发过来的信息", event, message);
          goBack();
        });
      }
      window.MaterialPptGoBack = goBack;
      // 这里做一个版本号的判断，为了兼容
      let tempVer = store.state.version;
      isHighVersion.value = _.gte(store.state.version, "1.4.3");
      if (firstLoadType === "true") {
        loadPPT();
      } else {
        const bookId = route.query.bookId;
        const bookUrl = route.query.url;
        const bookName = route.query.name;
        if (bookType.value === "official") {
          data = await getSections(bookId);
          materialType.value = 0;
        }
        if (bookType.value === "characteristicClassroom") {
          data = await getSections(bookId);
          materialType.value = 1;
        }
        if (bookType.value === "myCourse") {
          data = await getTeacherSections(bookId);
        }
        initializationTreeData.value = data.data;
        // 处理数据获取是否有本地缓存
        console.log("localResourceState", localResourceState.value);
        if (localResourceState.value) {
          if (isHighVersion.value) {
            finishedLocalCacheData.value = await handleHighLocalCacheData(
              data.data
            );
          } else {
            finishedLocalCacheData.value = await handleLocalCacheData(
              data.data
            );
          }
          console.log("本地缓存1", finishedLocalCacheData.value);
          // 走新接口，查不到本地值，返回空
          const LocalCacheData = await isLocalCacheData(
            finishedLocalCacheData.value
          );
          console.log("本地缓存2", LocalCacheData);

          let tempLocalCacheData = LocalCacheData.data;
          if (isHighVersion.value) {
            tempLocalCacheData = handleCloudId(tempLocalCacheData);
          }
          finishedLocalCacheData.value = tempLocalCacheData;
        }
        console.log("treedataeeaa", finishedLocalCacheData.value);
        const courseData = data.data || [];
        const newTreeData = filterParams(courseData);
        console.log("newTreeData", newTreeData);
        if (route.query.memory) {
          console.log("教学记忆跳转");
          firstParams = memoryFirstTreeForeach(courseData);
          if (!firstParams || firstParams.url === undefined) {
            firstParams = null;
          }
        } else {
          console.log("教材跳转");
          firstParams = firstTreeForeach(courseData);
        }

        console.log("开始处理教材树的数据firstParams", firstParams);
        if (firstParams !== null) {
          // 将数据和本地是否有资源的数据进行比较，重新处理数据
          let firstPPTUrl = firstParams.url;
          finishedLocalCacheData.value.find((data) => {
            console.log("获取了本地");
            if (
              data.id === firstParams.id &&
              data.file_info?.status === "success"
            ) {
              // 本地缓存资源数据中存在（不管本地资源是否存在）就从本地缓存资源数据中取url地址
              console.log("获取了本地的url11");
              firstPPTUrl = data.url;
              return true;
            } else {
              console.log("获取了本地的url22");
              firstPPTUrl = firstParams.url;
            }
          });
          _.merge(firstPptUrl, {
            id: firstParams.id,
            url: firstPPTUrl,
            runtime: {
              demMoreResourceBtn: firstParams.demMoreResourceBtn,
              state: {
                slideIndex: 0,
                screenType: "preview",
                screening: true,
              },
            },
          });
          previousPptUrl = firstPptUrl;
          nextPptUrl = firstPptUrl;
          _.merge(clickPptUrl, {
            id: firstParams.id,
            url: firstPPTUrl,
            runtime: {
              demMoreResourceBtn: firstParams.demMoreResourceBtn,
              state: {
                slideIndex: 0,
                screenType: "preview",
                screening: true,
              },
            },
          });
        } else {
          pptLoading.value = true;
        }
        treeData.value = newTreeData;
        console.log("treeData.value", treeData.value);

        // 第一次进入教材时获取第一个节点为教学记忆
        if (!route.query.memory && firstParams !== null) {
          console.log("获取第一个课时为教学记忆");
          const firstNode = getFirstTreeNode(newTreeData);
          getTeachMemory(firstNode, treeData.value);
        }
        url.value = bookUrl;
        materialName.value = decodeURI(bookName);
        if (firstLoadType === "false") {
          setTimeout(() => {
            loadPPT();
          }, 800);
        }
        getInsInfo();
      }
      // 接收子应用全屏命令
      eventCenter.sub(event.COMMON_FULL_SCREEN, (res) => {
        //           pptNowPlayMode.value = "preview";
        logger.info("全屏");
        // res:获取事件a的参数
        if (res.fullScreen) {
          dvLeftShow.value = false;
          fullScreen.value = true;
          if (morePptShow.value) {
            pptNowPlayMode.value = "demonstrate";
          }
          // enlarge();
        } else {
          // ppt资源预览时，隐藏左边栏
          if (morePptShow.value) {
            pptNowPlayMode.value = "preview";
          } else {
            dvLeftShow.value = true;
            fullScreen.value = false;
          }
          // if (!morePptShow.value) {
          //   dvLeftShow.value = true;
          //   pptNowPlayMode.value = "preview";
          // } else {
          //   pptNowPlayMode.value = "preview";

          //   fullScreen.value = false;
          // }
        }
      });
      // 接收子应用message
      eventCenter.sub(event.COMMON_NOTIFY_MESSAGE, (res) => {
        logger.info("ppt发过来的消息", res);
        const { type, element } = res;
        antdMessage.config(
          {
            top: `100px`,
            maxCount: 3,
            getContainer: () => element || document.body,
          },
          10
        );
        // const msg = res.message;
        const msg = (
          <span style="margin-left:10px; font-size:32px">{res.message}</span>
        );
        let msgFunc = null;
        switch (type) {
          case "info":
            msgFunc = antdMessage.info;
            break;
          case "error":
            msgFunc = antdMessage.error;
            break;
          case "success":
            msgFunc = antdMessage.success;
            break;
          case "warning":
            msgFunc = antdMessage.warning;
            break;
          default:
            msgFunc = antdMessage.info;
        }
        msgFunc &&
          msgFunc(msg, 3, () => {
            antdMessage.destroy();
          });
      });
      eventCenter.sub("openMoreDailog", ({ slideIndex = 0 } = {}) => {
        logger.info("slideIndex", slideIndex);
        handleOpenMoreResource(previousPptUrl);
        _.merge(clickPptUrl, {
          runtime: {
            state: {
              slideIndex,
              screenType: "demonstrate",
            },
          },
        });
        openMoreDailogSource.value = "inppt";
      });
      // 接收子应用翻页命令
      eventCenter.sub("PPT_DOUBLE_CLICK_PAGE_BTN", (res) => {
        logger.info("翻页的res==>", res);
        // 向子应用发送消息
        if (res.type === "previous") {
          logger.info("在这里加载上一章的最后一节");
          handlePreviousPPT();
        }
        if (res.type === "next") {
          logger.info("在这里加载下一章的第一节");
          handleNextPPT();
        }
      });
      // 滑屏初始化
      init();
      console.timeEnd("初始化数据");
      logger.info(
        "store里取出来的minimizePPTInfo===>",
        store.state.minimizePPTInfo
      );
      logger.info("store里取出来的minimizeUrl===>", store.state.minimizeUrl);
    });
    onBeforeMount(() => {
      // 判断是否存在最小化，同时是否全屏播放
      if (
        store.state.minimizeBtnState !== "false" &&
        store.state.minimizePPTInfo?.runtime?.state?.screenType ===
          "demonstrate"
      ) {
        dvLeftShow.value = false;
      }
      if (store.state.moreResourceClickData !== "") {
        reLoading.value = true;
      }
    });
    onUnmounted(async () => {
      store.commit(MutationNames.CHANGE_PPTTREEID, previousPptUrl.id);
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.removeAllListeners(["pptGoBack"]);
      }
      socket.off(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.off(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
      socket.off(
        SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
        noticeCacheErrorDownloadResult
      );
      // 离开页面时获取当前选中ppt的信息
      eventCenter.send("PPT_GET_STATUS", {}, (res) => {
        logger.info("页面卸载时保存ppt的信息", res);
        logger.info("页面卸载时isOpenMorePpt", isOpenMorePpt.value);
        // 是否打开更多资源中的ppt
        if (isOpenMorePpt.value) {
          // 保存到更多资源ppt
          store.commit(MutationNames.CHANGE_MOREPPTINFO, res);
          logger.info("两个ppt的状态更多的ppt", store.state.morePPTInfo);
          logger.info("两个ppt的状态树的ppt", store.state.minimizePPTInfo);
        } else {
          // 清除更多资源的全局变量
          store.commit(MutationNames.CHANGE_MORERESOURCEDATA, "");
          // 清除更多资源中点击资源的全局变量
          store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
          // 清除保存到更多资源ppt
          store.commit(MutationNames.CHANGE_MOREPPTINFO, "");
          // 保存到ppt
          store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
        }
      });
      setTimeout(async () => {
        // 用于pc端，goback返回时卸载ppt
        if (!isAndroid.value) {
          if (pptFun) {
            if (pptFun.preUnmount) {
              await pptFun.preUnmount();
            }
            pptFun.unmount();
            logger.info("卸载成功");
          } else {
            logger.info("没有卸载直接返回");
          }
        }
      }, 100);

      // store.commit(MutationNames.CHANGE_TEACHMEMORY, {}); //注释掉是因为从教材记忆进入后点击首页，再课件快速跳转打不开，并且清空最近打开模块数据
    });
    //  初始化滑屏
    const init = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };

    // 判断树是否全都没有ppt数据
    const isEmptyUrlTree = (data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children) {
          const res = isEmptyUrlTree(data[i].children);
          if (res) {
            return true;
          }
        } else {
          if (data[i].pptUrl && data[i].pptUrl !== undefined) {
            return true;
          }
        }
      }
      return false;
    };

    const loadPPT = async () => {
      // 下一课时跳转获取教学记忆,使下一课时数据变成当前课时
      if (
        route.query.memory === "nextPeriod" ||
        route.query.memory === "morePeriodNext"
      ) {
        getTeachMemory(
          store.state.teachMemory.nextPeriod[materialType.value],
          treeData.value
        );
      }
      if (firstParams === null) {
        const isEmpty = isEmptyUrlTree(treeData.value);
        if (isEmpty) {
          console.log("仍有节点存在ppt资源,需要加载ppt");
          pptLoadTxt.value = "当前节点无ppt资源，需要添加ppt资源";
        } else {
          console.log("所有章节均无PPT资源，请先添加PPT资源！");
          pptLoadTxt.value = "所有章节均无PPT资源，请先添加PPT资源！";
          return;
        }
      }
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      if (store.state.minimizePPTInfo.runtime == undefined) {
        previousPptUrl = firstPptUrl;
      } else {
        Object.assign(previousPptUrl, {
          id: store.state.pptTreeId,
          url: store.state.minimizePPTInfo.url,
          runtime: store.state.minimizePPTInfo.runtime,
        });
        logger.info("store.state.pptTreeId", store.state.pptTreeId);
      }
      nextPptUrl = previousPptUrl;
      // 通知上级应用 可以加载第一份ppt
      eventCenter.sub(`teachPptPC-load-ok`, () => {
        // 通过minimizeBtnState 快速唤醒ppt
        if (store.state.minimizeBtnState !== "false") {
          store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
          for (var i = 0; i < tree.value.store._getAllNodes().length; i++) {
            tree.value.store._getAllNodes()[i].expanded = false;
          }
          let minimizePPTInfo = store.state.minimizePPTInfo;
          minimizePPTInfo.runtime.autoLoadPNPpt = true;
          // if (minimizePPTInfo.runtime.state.screenType === "demonstrate") {
          //   dvLeftShow.value = false;
          //   fullScreen.value = true;
          // }
          tree.value.setCurrentKey(store.state.pptTreeId);
          eventCenter.send(event.PPTIST_LOAD_PPT, minimizePPTInfo, (res) => {
            pptLoading.value = false;
            setTimeout(() => {
              if (router?.currentRoute.value?.path?.includes("/materialPpt")) {
                // 仅页面还在教材页面时，才重置
                store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
              }

              const moreResourceData = store.state.moreResourceData;
              logger.info("更多moreResourceData", moreResourceData);
              if (store.state.moreResourceClickData !== "") {
                isReturnToPPT.value = true;
                handleOpenMoreResource(moreResourceData);
              }
            }, 2000);
          });
        } else if (
          route.query.memory === "morePeriod" ||
          route.query.memory === "morePeriodNext"
        ) {
          // 教学记忆跳转还原ppt
          let periodInfo = store.state.teachMemory.period[materialType.value];
          Object.assign(clickPptUrl, {
            id: periodInfo.id,
            url: periodInfo.pptUrl,
            runtime: {
              autoLoadPNPpt: true,
              demMoreResourceBtn: periodInfo.courseware_count > 0,
              state: {
                slideIndex: 0,
                screenType: "preview",
                screening: true,
              },
            },
          });
          eventCenter.send(event.PPTIST_LOAD_PPT, clickPptUrl, (res) => {
            pptLoading.value = false;
            setTimeout(() => {
              const moreResourceData = periodInfo;
              isReturnToPPT.value = true;
              logger.info("更多moreResourceData", moreResourceData);
              handleOpenMoreResource(moreResourceData);
            }, 2000);
          });
        } else {
          firstPptUrl.runtime.autoLoadPNPpt = true;
          logger.info("加载第一份ppt", firstPptUrl);
          eventCenter.send(event.PPTIST_LOAD_PPT, firstPptUrl, (res) => {
            pptLoading.value = false;
          });
        }
      });
      setTimeout(() => {
        pptLoading.value === true && (pptLoading.value = false);
      }, 30000);
      const env = JSON.stringify(process.env.RELEASE_ENV);
      // let entryUrl = "http://192.168.113.138:8090/";
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }

      pptFun = await loadMicroApp(
        {
          name: "teachPptPC",
          entry: entryUrl,
          container: "#ppt-container",
          props: {
            // preload: true ppt预加载的时候要传过去的参数
            args: {
              token: store.state.token,
              appName: "teachPptPC",
              runtime: {
                playPptRule: {
                  play: "demonstrate",
                  stop: "preview",
                },
              },
              os: store.state.hardwareModel,
              // preload: true
            },
            micrAppUrl: entryUrl,
            screening: true,
            screenType: "preview",
          },
        },
        {
          sandbox: { experimentalStyleIsolation: true },
          $$cacheLifecycleByAppName: true,
        }
      );
      await pptFun.mountPromise;
      setMicroAppMap("teachPptPC", pptFun);
      console.timeEnd("时间评估 loadMicroApp");
      if (firstLoadType === "true") {
        // 通知机顶盒ppt预加载完成
        window.WebViewJavascriptBridge.callHandler(
          "pptPreloadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
        goBack();
      } else {
        logger.info("加载完成", firstParams);
        // setTimeout(() => {
        // handleFirstLoadingPPT();
        // }, 1800);
      }
    };

    const filterParams = (list) => {
      const params = [];
      list.forEach((el) => {
        const newNode = {
          id: el.id,
          label: el.name,
          type: el.type,
          courseware_id: el.section_resource?.id,
          courseware_count: el.courseware_count,
          version_time: el.section_resource?.updated_at,
          md5: el.section_resource?.md5,
          cloud_url: el.section_resource?.url,
        };
        if (newNode.type === 0) {
          newNode["localState"] = "success";
          newNode["localDownloadProgress"] = 100;
          newNode.children = filterParams(el.children || []);
        }
        // 缓存服务可用再执行新的逻辑，否则按旧的逻辑处理
        if (newNode.type === 1 && localResourceState.value) {
          // 将数据和本地是否有资源的数据进行比较，重新处理数据
          let isExitLocalCacheData = false;
          finishedLocalCacheData.value.find((data) => {
            isExitLocalCacheData = true;
            if (newNode.courseware_id !== undefined) {
              if (data.id === newNode.id && data.file_info !== null) {
                if (data.file_info?.status === "success") {
                  // 当缓存状态为成功时将地址替换为本地资源地址
                  newNode["pptUrl"] = data.file_info?.file_url;
                } else {
                  // 当缓存状态为下载中或者错误时将地址替换为线上资源地址
                  if (isHighVersion.value) {
                    newNode["pptUrl"] = el.section_resource?.url;
                  } else {
                    newNode["pptUrl"] = data.url;
                  }

                  // 获取缓存下载进度
                  if (data.file_info.status === "loading") {
                    const { complete_file_num, total_file_num } =
                      data.progress || {};
                    const currentProgress = total_file_num
                      ? Math.floor((complete_file_num / total_file_num) * 100)
                      : 0;
                    newNode["localDownloadProgress"] = currentProgress;
                  } else {
                    newNode["localDownloadProgress"] = undefined;
                  }
                }
                if (
                  data.file_info?.status === "success" ||
                  data.file_info?.status === "loading"
                ) {
                  newNode["localState"] = data.file_info?.status;
                }

                return true;
              } else {
                // 将地址替换为线上资源地址
                newNode["pptUrl"] = el.section_resource?.url;
                newNode["localState"] = "error";
                newNode["localDownloadProgress"] = undefined;
              }
            } else {
              // 将地址替换为线上资源地址
              newNode["pptUrl"] = el.section_resource?.url;
              newNode["localState"] = "error";
              newNode["localDownloadProgress"] = undefined;
              return true;
            }
          });
          if (!isExitLocalCacheData) {
            console.log("本地无缓存数据，走云端数据");
            newNode["pptUrl"] = el.section_resource?.url;
          }
        } else if (newNode.type === 1) {
          console.log("拿取云端数据");
          newNode["pptUrl"] = el.section_resource?.url;
        }
        params.push(newNode);
      });
      return params;
    };
    // // 上一页
    // const handlePrevious = () => {
    //   if (currentPage.value === 1) {
    //     message.value = "已经是第一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     const num = currentPage.value - 2;
    //     resourcesEight.length = 0;
    //     const startIndex = 8 * num;
    //     const endIndex = startIndex + 8;
    //     resourcesEight.push(...resourcesList.slice(startIndex, endIndex));
    //     currentPage.value--;
    //   }
    // };
    // // 下一页
    // const handleNext = () => {
    //   if (currentPage.value === totalPage.value) {
    //     message.value = "已经是最后一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     resourcesEight.length = 0;
    //     const startIndex = 8 * currentPage.value;
    //     let endIndex;
    //     if (startIndex + 8 > resourcesList.length) {
    //       endIndex = resourcesList.length;
    //     } else {
    //       endIndex = startIndex + 8;
    //     }
    //     resourcesEight.push(...resourcesList.slice(startIndex, endIndex));
    //     currentPage.value++;
    //   }
    // };
    const goBack = _.throttle(async () => {
      if (morePptShow.value) {
        // ppt预览时，返回功能不返回上层页面
        morePptShow.value = false;
        store.commit(MutationNames.CHANGE_MOREPPTSHOW, false);
        centerDialogVisible.value = true;
        resourcesEightTxt.value = "";
        middleResourcesEightTxt.value = "";
        lastResourcesEightTxt.value = "";
        dvLeftShow.value =
          openMoreDailogSource.value === "inppt" ? false : true; // todo: 这边需要判断是从哪个入口进去，来判断是不是退出还要全屏
        // if (fullScreen.value) {
        //   // 当点击返回按钮时，若ppt正在放映状态，则关闭结束放映状态
        //   fullScreen.value = false;
        //   // 获取结束放映按钮
        //   // const closeBtn = document
        //   //   .getElementsByClassName("exit-button")[0]
        //   //   ?.getElementsByTagName("button");
        //   // if (closeBtn[0].innerText === "结束放映") {
        //   //   closeBtn[0].click();
        //   // }
        // }
        // 清除更多资源的全局变量
        // store.commit(MutationNames.CHANGE_MORERESOURCEDATA, "");
        // 清除更多资源中点击资源的全局变量
        store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
        // 清除保存到更多资源ppt
        store.commit(MutationNames.CHANGE_MOREPPTINFO, "");
        logger.info("go back clickPptUrl", clickPptUrl);
        if (clickPptUrl.runtime.state.screenType === "demonstrate") {
          logger.info("全屏");
          dvLeftShow.value = false;
          fullScreen.value = true;
        }
        // 向子应用发送消息,还原ppt预览前的页面
        clickPptUrl.runtime.autoLoadPNPpt = true;
        eventCenter.send(event.PPTIST_LOAD_PPT, clickPptUrl, (res) => {
          logger.info("res", res);
        });

        // if (window.require) {
        //   const { ipcRenderer } = window.require("electron");
        //   ipcRenderer.once("pptGoBack", (event, message) => {
        //     // 接收electron原生返回的信息
        //     logger.info("我接收到的electron发过来的信息1", event, message);
        //     goBack();
        //   });
        // }
      } else {
        if (pptFun) {
          if (pptFun.preUnmount) {
            await pptFun.preUnmount();
          }
          await pptFun.unmount();
        } else {
          logger.info("没有卸载直接返回");
        }
        // history.go(-1);
        //  history.back();
        const skinType = route.query.skinType;
        router.push({
          path:
            skinType === "cartoon" ? "/teachMaterialCartoon" : "/teachMaterial",
          query: {
            teachMaterialType: bookType.value,
          },
        });
      }
    }, 5000);
    // 数据埋点
    const handleDataReport = (item, type) => {
      let eventattr = { id: item.id };
      if (type === "more") {
        let ownerType = "";
        if (currentTab.value === "platform") {
          ownerType = "official";
        } else if (currentTab.value === "institution") {
          ownerType = "institution";
        } else {
          ownerType = "school";
        }
        eventattr = {
          id: item.id,
          owner_type: ownerType,
        };
      }
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (!isAndroid.value) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_teachmaterial_open",
          pageid: "teachmaterial",
          moduleid: "teaching",
          eventattr: eventattr,
        };
        const body = encodeURIComponent(JSON.stringify(data));
        dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "teaching_teachmaterial_open";
            data.pageid = "teachmaterial";
            data.moduleid = "teaching";
            data.eventattr = eventattr;
            const body = encodeURIComponent(JSON.stringify(data));
            dataEmbeddingPoint(body);
          }
        );
      }
    };
    const handleNodeClick = (data) => {
      console.log("data", data);
      if (data.pptUrl && data.pptUrl !== undefined) {
        pptLoading.value = false;
      }
      // const test = ref.tree.getCheckedKeys();
      // 是否打开更多资源中的ppt
      isOpenMorePpt.value = false;
      // 离开页面的时候将当前选中课时的节点id保存到全局变量中
      store.commit(MutationNames.CHANGE_PPTTREEID, data.id);
      logger.info("treeNodeId====>", data.$treeNodeId);
      // 第二个参数代表 more||更多资源；node||节点
      handleDataReport(data, "node");
      if (data.children) {
        logger.info("data.children");
      } else {
        Object.assign(clickPptUrl, {
          id: data.id,
          url: data.pptUrl,
          runtime: {
            autoLoadPNPpt: true,
            demMoreResourceBtn: data.courseware_count > 0,
            state: {
              slideIndex: 0,
              screenType: "preview",
              screening: true,
            },
          },
        });
        previousPptUrl = clickPptUrl;
        nextPptUrl = clickPptUrl;
        logger.info("发送前查看当前ppt的url===>", clickPptUrl);
        // 向子应用发送消息
        eventCenter.send(event.PPTIST_LOAD_PPT, clickPptUrl, (res) => {
          logger.info("PPTIST_LOAD_PPT", res);
        });
      }
      // 点击节点存储当前节点和下一节点信息
      getTeachMemory(data, treeData.value);
    };
    const handleNodeClickMore = (data) => {
      logger.info("handleNodeClickMore", data);
    };

    // 根据当前选中的节点,展开树
    const getDefaultExpendedKeys = (data, flag) => {
      for (let i = 0; i < data.length; i++) {
        // 如果存在子节点开始递归
        if (data[i].children) {
          const res = getDefaultExpendedKeys(data[i].children, flag);
          if (res) {
            // treeParams 用于控制tree组件默认展开节点
            treeParams.value.push(data[i].id);
            return res;
          }
        } else {
          if (data[i].id === flag) {
            return data[i];
          }
        }
      }
      return null;
    };

    // 获取第一个节点
    const getFirstTreeNode = (data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].type === 0) {
          const res = getFirstTreeNode(data[i].children);
          if (res) {
            return res;
          }
        } else {
          return data[i];
        }
      }
    };

    // 从教学记忆跳转处理第一个数据
    const memoryFirstTreeForeach = (data) => {
      // 如果从教学记忆跳转，返回对应的最近课时节点数据
      let teachPeriod = store.state.teachMemory.period[materialType.value];
      let teachNextPeriod =
        store.state.teachMemory.nextPeriod[materialType.value];
      console.log("teachPeriod", teachPeriod, teachNextPeriod);

      if (
        (route.query.memory === "period" ||
          route.query.memory === "morePeriod") &&
        teachPeriod.pptUrl !== undefined
      ) {
        // 默认选中教学记忆对应课时
        treeCurrentNodeKey.value = teachPeriod.id;
        // 展开对应节点
        getDefaultExpendedKeys(data, teachPeriod.id);
        return {
          id: teachPeriod.id,
          url: teachPeriod.pptUrl,
          demMoreResourceBtn: teachPeriod.courseware_count > 0,
        };
      }
      if (
        (route.query.memory === "nextPeriod" ||
          route.query.memory === "morePeriodNext") &&
        teachNextPeriod.pptUrl !== undefined
      ) {
        treeCurrentNodeKey.value = teachNextPeriod.id;
        getDefaultExpendedKeys(data, teachNextPeriod.id);
        return {
          id: teachNextPeriod.id,
          url: teachNextPeriod.pptUrl,
          demMoreResourceBtn: teachNextPeriod.courseware_count > 0,
        };
      }
    };

    // 获取ppt加载的第一个节点数据
    const firstTreeForeach = (data) => {
      // 返回第一个节点
      let param = null;
      for (let i = 0; i < data.length; i++) {
        const el = data[i];
        if (el.type === 0) {
          treeParams.value.push(el.id);
          param = firstTreeForeach(el.children || []);
          if (param === null) {
            treeParams.value.pop();
          } else {
            return param;
          }
        }
        if (el.type === 1) {
          if (el.section_resource) {
            treeCurrentNodeKey.value = el.id;
            return {
              id: el.id,
              url: el.section_resource?.url,
              demMoreResourceBtn: el.courseware_count > 0,
            };
          }
        }
      }
      return null;
    };

    // 全屏
    const enlarge = () => {
      // 判断设备能否全屏
      const fullarea = document.getElementById("ppt-container");
      setTimeout(() => {
        if (fullarea.requestFullscreen) {
          fullarea.requestFullscreen();
        } else if (fullarea.webkitRequestFullScreen) {
          fullarea.webkitRequestFullScreen(); // webkit内核(chrome、safari、Opera等)
        } else if (fullarea.mozRequestFullScreen) {
          fullarea.mozRequestFullScreen(); // moz内核(firefox)
        } else if (fullarea.msRequestFullscreen) {
          fullarea.msRequestFullscreen(); // IE11、edge
        }
      }, 0);
      isEnlarge.value = true;
    };
    // 退出全屏
    const narrow = () => {
      setTimeout(() => {
        document.webkitExitFullscreen();
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen(); // webkit内核(chrome、safari、Opera等)
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen(); // moz内核(firefox)
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen(); // IE11、edge
        }
      }, 0);
      isEnlarge.value = false;
    };
    // 更多资源获取本地是否有缓存资源
    const getLocalResourceState = async (data) => {
      let res = data;
      console.log("获取更多资源的数据data", data.data.items);
      let moreResource = [];
      if (isHighVersion.value) {
        data.data.items.forEach((el) => {
          const newNode = {
            cloud_id: el.id,
            type: el.type.toUpperCase(),
            version_time: el.updated_at,
            source: sourceUtil(sourceOrigin.value),
          };
          moreResource.push(newNode);
        });
        moreResource = { items: moreResource };
      } else {
        data.data.items.forEach((el) => {
          const newNode = {
            id: el.id,
            md5: el.md5,
            version_time: el.updated_at,
            url: el.url,
          };
          moreResource.push(newNode);
        });
      }

      console.log("获取更多资源的数据moreResource", moreResource);
      // 将moreResource发送给缓存服务换回数据 在数据里增加localState
      const localMoreResource = await isLocalCacheData(moreResource);
      console.log("aa", localMoreResource);

      // 将缓存服务返回的数据拼回传入的数据并且返回回去
      res.data.items.forEach((el, index) => {
        let isExit = false;
        localMoreResource.data.find((data) => {
          if (el.id === data.id || el.id === data.cloud_id) {
            const { status, file_url } = data.file_info || {};
            if (status && (status === "success" || status === "loading")) {
              isExit = true;
              res.data.items[index].url = file_url;
              res.data.items[index].localState = status;
              // 获取缓存下载进度
              if (status === "loading") {
                const { complete_file_num, total_file_num } =
                  data.progress || {};
                const currentProgress = total_file_num
                  ? Math.floor((complete_file_num / total_file_num) * 100)
                  : 0;
                res.data.items[index].localDownloadProgress = currentProgress;
              } else {
                res.data.items[index].localDownloadProgress = undefined;
              }
              return true;
            }
          }
        });
        if (!isExit) {
          res.data.items[index].localDownloadProgress = undefined;
        }
      });
      console.log("res1112", res, localMoreResource);
      return res;
    };
    const handleOpenMoreResource = async (data) => {
      console.log("handleOpenMoreResource", data);
      resourcesEightTxt.value = "";
      middleResourcesEightTxt.value = "";
      lastResourcesEightTxt.value = "";
      centerDialogVisible.value = true;
      // 处理打开更多资源的id
      let clickPptUrlData = { id: "" };
      if (data === undefined) {
        if (store.state.pptTreeId) {
          clickPptUrlData.id = store.state.pptTreeId;
        } else {
          clickPptUrlData.id = clickPptUrl.id;
        }
      } else {
        clickPptUrlData.id = data.id;
      }
      // 将当前点击的节点保存到全局变量
      store.commit(MutationNames.CHANGE_MORERESOURCEDATA, clickPptUrlData);
      mySwiper.value.touches.diff = 0;
      currentMoreResourceSectionId.value = clickPptUrlData.id;
      // 处理选中的tab
      if (store.state.moreResourceClickData !== "") {
        // 从全局变量中获取参数
        currentTab.value = store.state.moreResourceTabType;
        currentPage.value = store.state.moreResourceTabPage;
      } else {
        currentTab.value = TabTypeMap.Platform;
        currentPage.value = 1;
      }
      // 滑动模块左边和右边的模块对应的页数
      let leftBorderPage = currentPage.value - 1;
      let rightBorderPage = currentPage.value + 1;
      if (currentPage.value === 1) {
        leftBorderPage = 2;
        rightBorderPage = 3;
      }
      logger.info("调接口前查看tab值===》", currentMoreResourceSectionId.value);
      const searchFunc = getResourceFuncByTabType();
      const res = await searchFunc({
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: currentPage.value,
        is_primary: 0,
      });
      console.log("获取更多资源的数据", res);
      let finishedData = res;
      // 当版本支持本地缓存时启用本地缓存
      if (localResourceState.value) {
        finishedData = await getLocalResourceState(res);
      }
      console.log("获取更多资源的数据aa", finishedData);
      console.log("获取更多资源的数据bb", resourcesEight);
      if (currentPage.value % 3 === 1) {
        resourcesEight.length = 0;
        resourcesEight.push(...finishedData.data.items);
        mySwiper.value.activeIndex = 1;
      } else if (currentPage.value % 3 === 2) {
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...finishedData.data.items);
        mySwiper.value.activeIndex = 2;
      } else {
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...finishedData.data.items);
        mySwiper.value.activeIndex = 3;
      }
      totalPage.value = finishedData.data.meta.last_page;
      if (totalPage.value === 0) {
        totalPage.value = 1;
      }
      // 选择类型或搜索之后判断边界值
      if (currentPage.value === 1 && totalPage.value !== 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = true;
      } else if (
        currentPage.value === totalPage.value &&
        totalPage.value === 1
      ) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = false;
      } else if (
        currentPage.value === totalPage.value &&
        totalPage.value !== 1
      ) {
        leftBorderFlag.value = true;
        rightBorderFlag.value = false;
      } else {
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
      }
      const middleData = await searchFunc({
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: leftBorderPage,
        is_primary: 0,
      });
      console.log("获取更多资源的数据", middleData);
      let finishedMiddleData = middleData;
      // 当版本支持本地缓存时启用本地缓存
      if (localResourceState.value) {
        finishedMiddleData = await getLocalResourceState(middleData);
      }
      console.log("获取更多资源的数据aa", finishedMiddleData);
      // 在这里判断数据放到哪一个窗口
      if (leftBorderPage % 3 === 1) {
        resourcesEight.length = 0;
        resourcesEight.push(...finishedMiddleData.data.items);
      }
      if (leftBorderPage % 3 === 2) {
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...finishedMiddleData.data.items);
      }
      if (leftBorderPage % 3 === 0) {
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...finishedMiddleData.data.items);
      }
      totalPage.value = finishedMiddleData.data.meta.last_page;
      if (totalPage.value === 0) {
        totalPage.value = 1;
      }
      const lastData = await searchFunc({
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: rightBorderPage,
        is_primary: 0,
      });
      console.log("获取更多资源的数据", lastData);
      let finishedLastData = lastData;
      // 当版本支持本地缓存时启用本地缓存
      if (localResourceState.value) {
        finishedLastData = await getLocalResourceState(lastData);
      }
      console.log("获取更多资源的数据aa", finishedLastData);
      // 在这里判断数据放到哪一个窗口
      if (rightBorderPage % 3 === 1) {
        resourcesEight.length = 0;
        resourcesEight.push(...finishedLastData.data.items);
      }
      if (rightBorderPage % 3 === 2) {
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...finishedLastData.data.items);
      }
      if (rightBorderPage % 3 === 0) {
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...finishedLastData.data.items);
      }
      totalPage.value = finishedLastData.data.meta.last_page;
      if (totalPage.value === 0) {
        totalPage.value = 1;
      }
      if (resourcesEight.length === 0) {
        logger.info("resourcesEight.length === 0");
        resourcesEightTxt.value = "暂无数据";
        noDataMsg.value = true;
      } else {
        resourcesEightTxt.value = "";
      }
      if (middleResourcesEight.length === 0) {
        middleResourcesEightTxt.value = "暂无数据";
        noDataMsg.value = true;
      } else {
        middleResourcesEightTxt.value = "";
      }
      if (lastResourcesEight.length === 0) {
        lastResourcesEightTxt.value = "暂无数据";
        noDataMsg.value = true;
      } else {
        lastResourcesEightTxt.value = "";
      }
      firstLoading.value = false;
      if (currentPage.value % 3 === 1) {
        mySwiper.value.activeIndex = 1;
      } else if (currentPage.value % 3 === 2) {
        mySwiper.value.activeIndex = 2;
      } else {
        mySwiper.value.activeIndex = 3;
      }
      openMoreDailogSource.value = "outppt";
      _.merge(clickPptUrl, {
        runtime: {
          state: {
            screenType: "preview",
          },
        },
      });
      setTimeout(() => {
        if (store.state.moreResourceClickData !== "") {
          // 如果点击更多窗口的swf文件 除教学记忆外不能调用还原
          if (
            store.state.moreResourceClickData?.type === "swf" &&
            !route.query.memory
          ) {
            return;
          }
          logger.info(
            "还原更多资源点击事件",
            store.state.moreResourceClickData
          );
          handleCardClick(store.state.moreResourceClickData);
        }
      }, 1000);
    };
    // 更多资源中的资源点击事件
    const handleCardClick = (data) => {
      logger.info("handleCardClick", data);
      // 将更多资源点击操作的数据保存到全局变量
      store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, data);
      // 第二个参数代表 more||更多资源；node||节点
      handleDataReport(data, "more");
      const url = data.url;
      if (currentTab.value === TabTypeMap.Platform) {
        addViewLog(data.popularity.courseware_id);
      } else {
        addInsViewLog(data.popularity.courseware_id);
      }
      data.popularity.popularity++;
      if (data.type !== "ppt") {
        showResourceRef.value.handleShowResource(
          data.type,
          true,
          url,
          toRaw(data)
        );
        reLoading.value = false;
        if (store.state.moreResourceClickData?.type === "swf") {
          store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
        }
      } else {
        // 是否打开更多资源中的ppt
        isOpenMorePpt.value = true;
        // 第一步获取到当前打开ppt的状态 并且加上id之后放到clickPptUrl中
        eventCenter.send("PPT_GET_STATUS", {}, (res) => {
          logger.info("更多资源保存ppt信息res", res);
          logger.info("更多资源保存ppt信息clickPptUrl", clickPptUrl);
          const treeID = clickPptUrl.id;
          store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
          clickPptUrl = res;
          clickPptUrl.id = treeID;
          logger.info("update更多资源保存ppt信息clickPptUrl", clickPptUrl);
          logger.info(
            "更多资源保存ppt信息minimizePPTInfo",
            store.state.minimizePPTInfo
          );
          // 第二步将更多资源中的ppt信息通知给ppt;
          setTimeout(() => {
            let clickUrl = {
              url,
              runtime: {
                demMoreResourceBtn: false,
                state: {
                  slideIndex: 0,
                  screenType: "preview",
                  screening: true,
                },
              },
            };
            if (store.state.morePPTInfo !== "") {
              clickUrl = store.state.morePPTInfo;
            }
            clickUrl.runtime.autoLoadPNPpt = false;
            logger.info("打开更多资源中的ppt", clickUrl);
            setTimeout(() => {
              // 向子应用发送消息
              eventCenter.send(event.PPTIST_LOAD_PPT, clickUrl, (res) => {
                centerDialogVisible.value = false;
                reLoading.value = false;
                morePptShow.value = true;
                store.commit(MutationNames.CHANGE_MOREPPTSHOW, true);
                dvLeftShow.value = false;
                pptNowPlayMode.value = "preview";
                logger.info(
                  "我要准备改morePptShow.value",
                  store.state.morePptShow
                );
                setTimeout(() => {
                  const backBtn =
                    document.getElementsByClassName("morePptShowBackBtn");
                  // 开始放映按钮节点
                  // const startBtn =
                  //   document.getElementsByClassName("demonstrate-button");
                  backBtn[0].style.left = "1473px";
                  // startBtn[0].getBoundingClientRect().x - 90 + "px";
                  backBtn[0].style.top = "801.312px"; // startBtn[0].getBoundingClientRect().y + "px";
                }, 1000);
              });
            }, 1000);
          }, 100);
        });
      }
    };
    // 资源类型
    const bindIcon = (icon) => {
      return require("@/assets/img/myCourseWare/courseWare/" + icon + ".png");
    };
    const handleCopyFromOfficial = (data) => {
      let id;
      if (data.popularity) {
        id = data.popularity.courseware_id;
      } else {
        id = data.courseware_id;
      }
      if (!id) {
        ElMessage({
          offset: 200,
          message: "获取失败，该课件暂无资源",
          center: true,
          type: "error",
        });
        return;
      }
      copyOfficial({
        official_courseware_id: id,
      }).then(() => {
        ElMessage({
          offset: 200,
          message: "课件已获取成功，可前往我的课件查看",
          center: true,
          type: "success",
        });

        addDownloadLog(id);
      });
    };

    const getResourceFuncByTabType = () => {
      let func = undefined;
      switch (currentTab.value) {
        case TabTypeMap.Platform:
          func = getCoursewares;
          break;
        case TabTypeMap.Institution:
          func = getInstitutionCoursewares;
          break;
        case TabTypeMap.School:
          func = getSchoolCoursewares;
          break;
        default:
          func = getCoursewares;
          break;
      }
      return func;
    };
    const getTeacherData = () => {
      getTeacherInfo({}).then((data) => {
        schoolId.value = data.school_id;
      });
    };
    const getInsInfo = () => {
      getSchoolInstitutionInfo(schoolId.value).then(({ data }) => {
        if (data && data.uplevel_ins_data && data.uplevel_ins_data.length > 0) {
          showIns.value = true;
        }
      });
    };
    // tab栏修改
    const handleTabClick = async (type) => {
      sourceOrigin.value = type; // source platform school institution
      store.commit(MutationNames.CHANGE_MORERESOURCETABTYPE, type);
      mySwiper.value.touches.diff = 0;
      if (currentTab.value !== type) {
        currentTab.value = type;
        const params = {
          book_section_id: currentMoreResourceSectionId.value,
          per_page: 8,
          is_primary: 0,
        };
        const searchFunc = getResourceFuncByTabType();
        // begin await test
        const res = await searchFunc({ ...params, page: 1 });
        let finishedResData = res;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedResData = await getLocalResourceState(res);
        }
        resourcesEight.length = 0;
        resourcesList.length = 0;
        resourcesList = finishedResData.data.items;
        resourcesEight.push(...resourcesList.slice(0, 8));
        if (resourcesEight.length === 0) {
          resourcesEightTxt.value = "暂无数据";
          noDataMsg.value = true;
          isReturnToPPT.value = false;
        } else {
          resourcesEightTxt.value = "";
          isReturnToPPT.value = true;
        }
        // totalPage.value = parseInt(resourcesList.length / 8);
        // totalPage.value = Math.ceil(resourcesList.length / 8);
        totalPage.value = res.data.meta.last_page;
        if (totalPage.value === 0) {
          totalPage.value = 1;
        }
        // 选择类型或搜索之后判断边界值
        if (currentPage.value == 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if ((currentPage.value == totalPage.value) == 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        const middleRes = await searchFunc({ ...params, page: 2 });
        let finishedMiddleResData = middleRes;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedMiddleResData = await getLocalResourceState(middleRes);
        }
        middleResourcesEight.length = 0;
        resourcesList.length = 0;
        resourcesList = finishedMiddleResData.data.items;
        middleResourcesEight.push(...resourcesList.slice(0, 8));
        if (middleResourcesEight.length === 0) {
          middleResourcesEightTxt.value = "暂无数据";
          noDataMsg.value = true;
        } else {
          middleResourcesEightTxt.value = "";
        }
        // totalPage.value = parseInt(resourcesList.length / 8);
        // totalPage.value = Math.ceil(resourcesList.length / 8);
        totalPage.value = middleRes.data.meta.last_page;
        if (totalPage.value === 0) {
          totalPage.value = 1;
        }
        const lastRes = await searchFunc({ ...params, page: 2 });
        let finishedLastResData = lastRes;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedLastResData = await getLocalResourceState(lastRes);
        }
        lastResourcesEight.length = 0;
        resourcesList.length = 0;
        resourcesList = finishedLastResData.data.items;
        lastResourcesEight.push(...resourcesList.slice(0, 8));
        if (lastResourcesEight.length === 0) {
          lastResourcesEightTxt.value = "暂无数据";
          noDataMsg.value = true;
        } else {
          lastResourcesEightTxt.value = "";
        }
        totalPage.value = lastRes.data.meta.last_page;
        if (totalPage.value === 0) {
          totalPage.value = 1;
        }
        currentPage.value = 1;
        mySwiper.value.activeIndex = 1;
        openMoreDailogSource.value = "outppt";
        _.merge(clickPptUrl, {
          runtime: {
            state: {
              screenType: "preview",
            },
          },
        });
      }
    };
    const handlePreviousPPT = () => {
      // 这是当前ppt的对象
      logger.info("previousPptUrl====>", previousPptUrl);
      logger.info("treeData===>", treeData.value);
      const id = previousPptUrl.id;
      logger.info("previousPptUrlid", id);
      // 递归遍历所有type为1的结点放到数组里
      const readNodes = (nodes = [], tree = []) => {
        for (let item of nodes) {
          if (item.type === 1) {
            tree.push(item);
          }
          if (item.children && item.children.length) {
            readNodes(item.children, tree);
          }
        }
        return tree;
      };
      logger.info("递归结果在这===>", readNodes(treeData.value));
      const arr = readNodes(treeData.value);
      for (var i = 0; i < tree.value.store._getAllNodes().length; i++) {
        tree.value.store._getAllNodes()[i].expanded = false;
      }
      arr.forEach((item, index) => {
        if (item.id === id) {
          if (index !== 0) {
            logger.info(arr[index - 1]);
            handleChangePPT(arr[index - 1]);
            // 使节点高亮
            tree.value.setCurrentKey(arr[index - 1].id);
            // logger.info("查看已选中的数组===>", tree.value.getCheckedKeys());
            logger.info("查看树的参数===>", treeCurrentNodeKey.value);
          } else {
            ElMessage.warning({
              offset: 200,
              customClass: "edgeWarning",
              message: "没有上一章啦~",
              type: "warning",
            });
          }
        }
      });
    };
    const handleNextPPT = () => {
      const id = nextPptUrl.id;
      logger.info("handleNextPPT", id);
      // 递归遍历所有type为1的结点放到数组里
      const readNodes = (nodes = [], tree = []) => {
        for (let item of nodes) {
          if (item.type === 1) {
            tree.push(item);
          }
          if (item.children && item.children.length) {
            readNodes(item.children, tree);
          }
        }
        return tree;
      };
      logger.info("递归结果在这===>", readNodes(treeData.value));
      const arr = readNodes(treeData.value);
      for (var i = 0; i < tree.value.store._getAllNodes().length; i++) {
        tree.value.store._getAllNodes()[i].expanded = false;
      }
      arr.forEach((item, index) => {
        if (item.id === id) {
          if (index !== arr.length - 1) {
            logger.info("index", arr[index + 1].id);
            handleChangePPT(arr[index + 1]);
            // 使节点高亮
            tree.value.setCurrentKey(arr[index + 1].id);
            // logger.info("查看已选中的数组===>", tree.value.getCheckedKeys());
            logger.info("查看树的参数===>", treeCurrentNodeKey.value);
          } else {
            ElMessage.warning({
              offset: 200,
              customClass: "edgeWarning",
              message: "没有下一章啦~",
              type: "warning",
            });
          }
        }
      });
    };
    const handleChangePPT = (data) => {
      logger.info("data", data);
      getTeachMemory(data, treeData.value);
      // const test = ref.tree.getCheckedKeys();
      previousPptUrl.runtime.state.slideIndex = 0;
      previousPptUrl.runtime.state.pptUrl = data.pptUrl;
      const runtime = previousPptUrl.runtime;
      if (dvLeftShow.value) {
        runtime.state.screenType = "preview";
      } else {
        runtime.state.screenType = "demonstrate";
      }
      // 第二个参数代表 more||更多资源；node||节点
      // 数据埋点
      handleDataReport(data, "node");
      console.time("切换ppt加载时间");
      if (data.children) {
        logger.info("data.children");
      } else {
        logger.info("切换clickPptUrl", clickPptUrl);
        Object.assign(clickPptUrl, {
          id: data.id,
          url: data.pptUrl,
          runtime,
        });
        previousPptUrl = clickPptUrl;
        nextPptUrl = clickPptUrl;
        // store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
        if (data.courseware_count > 0) {
          clickPptUrl.runtime.demMoreResourceBtn = true;
        } else {
          clickPptUrl.runtime.demMoreResourceBtn = false;
        }
        clickPptUrl.runtime.autoLoadPNPpt = true;
        logger.info("发送前查看当前ppt的url===>", clickPptUrl);
        // 向子应用发送消息
        eventCenter.send(event.PPTIST_LOAD_PPT, clickPptUrl, (res) => {
          logger.info("res", res);
        });
        console.timeEnd("切换ppt加载时间");
      }
    };
    const onSwiper = (swiper) => {
      logger.info("swiper", swiper);
    };
    const onSlideChange = () => {
      logger.info("当前页码是===>", currentPage.value);
      if (mySwiper.value) {
        logger.info("当前页码是===>", mySwiper.value.activeIndex);
      }
    };
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      if (currentPage.value === totalPage.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
        // currentPage.value++;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      if (currentPage.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
        // currentPage.value--;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // logger.info("indexpre", startIndex, endIndex);
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = currentPage.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        currentPage.value--;
        if (currentPage.value !== 1) {
          getPrevData(currentPage.value - 1);
        }
      }
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      if (!firstLoading.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = currentPage.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          currentPage.value++;
          if (currentPage.value !== totalPage.value) {
            getNextData(currentPage.value + 1);
          }
          // getNextData(currentPage.value + 1, params);
        }
      }
    };
    //  切换时获取下一页数据的函数
    const getNextData = (page) => {
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        // is_primary: 0,
        // per_page: 8,
        // page: page,
        // book_show_modules: 1,
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        // page: leftBorderPage,
        page: page,
        is_primary: 0,
      }).then(async (data) => {
        let finishedData = data;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        const switchTeachMaterialList = finishedData.data.items;
        if ((currentPage.value + 1) % 3 == 1) {
          resourcesEight.length = 0;
          resourcesEight.push(...switchTeachMaterialList);
          if (resourcesEight.length === 0) {
            resourcesEightTxt.value = "暂无数据";
          } else {
            resourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value + 1) % 3 == 2) {
          middleResourcesEight.length = 0;
          middleResourcesEight.push(...switchTeachMaterialList);
          if (middleResourcesEight.length === 0) {
            middleResourcesEightTxt.value = "暂无数据";
          } else {
            middleResourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value + 1) % 3 == 0) {
          lastResourcesEight.length = 0;
          lastResourcesEight.push(...switchTeachMaterialList);
          if (lastResourcesEight.length === 0) {
            lastResourcesEightTxt.value = "暂无数据";
          } else {
            lastResourcesEightTxt.value = "";
          }
        }
      });
    };
    //  切换时获取上一页数据的函数
    const getPrevData = (page) => {
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        // is_primary: 0,
        // per_page: 8,
        // page: page,
        // book_show_modules: 1,
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: page,
        is_primary: 0,
      }).then(async (data) => {
        let finishedData = data;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        const switchTeachMaterialList = finishedData.data.items;
        if ((currentPage.value - 1) % 3 == 1) {
          resourcesEight.length = 0;
          resourcesEight.push(...switchTeachMaterialList);
          if (resourcesEight.length === 0) {
            resourcesEightTxt.value = "暂无数据";
          } else {
            resourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value - 1) % 3 == 2) {
          middleResourcesEight.length = 0;
          middleResourcesEight.push(...switchTeachMaterialList);
          if (middleResourcesEight.length === 0) {
            middleResourcesEightTxt.value = "暂无数据";
          } else {
            middleResourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value - 1) % 3 == 0) {
          lastResourcesEight.length = 0;
          lastResourcesEight.push(...switchTeachMaterialList);
          if (lastResourcesEight.length === 0) {
            lastResourcesEightTxt.value = "暂无数据";
          } else {
            lastResourcesEightTxt.value = "";
          }
        }
      });
    };
    const closeCenterDialog = () => {
      console.log("关闭", resourcesEight);
      isReturnToPPT.value = false;
      centerDialogVisible.value = false;
      currentTab.value = TabTypeMap.Platform;
      store.commit(
        MutationNames.CHANGE_MORERESOURCETABTYPE,
        TabTypeMap.Platform
      );
      currentMoreResourceSectionId.value = undefined;
      resourcesEight.length = 0;
      middleResourcesEight.length = 0;
      lastResourcesEight.length = 0;
      noDataMsg.value = false;
      resourcesEightTxt.value = "";
      middleResourcesEightTxt.value = "";
      lastResourcesEightTxt.value = "";
      sourceOrigin.value = "platform";
    };

    // 递归查找下一节点
    const findNext = (data, flag) => {
      for (let i = 0; i < data.length; i++) {
        // 如果存在子节点开始递归
        if (data[i].children && data[i].children.length > 0) {
          const next = findNext(data[i].children, flag);
          if (next) {
            // 如果为最后一个节点 查找父节点的下一节点
            if (next === "last" && data[i + 1]) {
              if (data[i + 1].children && data[i + 1].children.length > 0) {
                return getFirstTreeNode(data[i + 1].children);
              } else {
                return data[i + 1];
              }
            }
            // 如果下一节点还有子节点,选取第一个子节点
            if (next.children && next.children.length > 0) {
              return getFirstTreeNode(next.children);
            }
            return next;
          }
        } else {
          if (data[i].id === flag) {
            // 如果没有下一节点, 则为最后一个节点
            if (!data[i + 1]) {
              return "last";
            } else {
              // 如果下一节点还有子节点,返回第一个子节点
              if (data[i + 1].children && data[i + 1].children.length > 0) {
                return getFirstTreeNode(data[i + 1].children);
              } else {
                // 返回下一节点
                return data[i + 1];
              }
            }
          }
        }
      }
      return null;
    };

    // 获取教学记忆需要的精品课件数据
    const getCourseWareMemory = async (data, type) => {
      // 获取到课时对应的更多ppt资源
      if (data.courseware_count > 0) {
        // 第一次获取ppt数据, 获取到总页数
        let res = await getCoursewares({
          book_section_id: data.id,
          is_primary: 0,
          page: 1,
        });
        let totalPage = res.data.meta.last_page;
        console.log("第一次获取到的总页数", totalPage);
        // 如果总页数>1,继续获取全部ppt数据
        if (totalPage > 1) {
          // 接口默认每页获取ppt数为 10 根据总页数获取全部ppt数量范围
          totalPage = 10 * res.data.meta.last_page;
          res = await getCoursewares({
            book_section_id: data.id,
            is_primary: 0,
            per_page: totalPage,
          });
        }
        // 获取到ppt数据数组
        const ppt = res.data.items;
        console.log("请求接口获取到的精品课件数据", res);
        console.log("totalPage 总课件数 ", totalPage);
        console.log("获取到的完整精品课件数据", ppt);
        // 调用获取ppt函数-
        const findPPT = findCourseWare(ppt);
        // 存储下一课时数据
        console.log("vuex couresWare");
        // type == true 获取当前课时 type == false 获取下一课时
        if (type) {
          courseWareMemroy[materialType.value] = findPPT;
        } else {
          courseWareMemroyNext[materialType.value] = findPPT;
        }
      } else {
        console.log("没有更多ppt资源");
      }
    };

    // 查找最受欢迎的ppt
    const findCourseWare = (data) => {
      // 如果不存在对应数据返回空值
      if (!data) {
        return {};
      } else {
        // 根据polularity 返回最受欢迎的ppt
        let max = {
          popularity: { popularity: 0 },
          updated_at: "",
        };
        for (let i of data) {
          if (
            i.popularity.popularity >= max.popularity.popularity &&
            i.type === "ppt"
          ) {
            // 如果受欢迎程度相同,返回最近更新的ppt
            if (i.popularity.popularity == max.popularity.popularity) {
              compareUpdate(i.updated_at, max.updated_at) ? (max = i) : max;
            }
            max = i;
          }
        }
        return max;
      }
    };

    // 对比更新时间函数
    const compareUpdate = (date1, date2) => {
      // 时间格式: "2022-09-19 14:26:11"
      const a = new Date(date1);
      const b = new Date(date2);
      return a.getTime() > b.getTime();
    };

    // 获取当前教学进度记忆数据
    const getTeachMemory = async (nodeData, list) => {
      // 数据不存在/如果有子节点则不获取
      if (nodeData.type === 0 && nodeData.children.length > 0) {
        console.log("不获取记忆数据");
        return;
      }
      // 存储最近课时数据
      memory[materialType.value] = nodeData;
      // 存储最近课时的下一个课时数据
      const flag = nodeData.id;
      let res = findNext(list, flag);
      console.log(res);
      // 如果当前节点为最后节点或下一节点为空
      if (res === "last" || !res) {
        res = {
          label: `已经是《${decodeURI(teachMaterialBook.name)}》最后一个课时`,
        };
      }
      // // 存储下一课时数据
      memoryNext[materialType.value] = res;
      getCourseWareMemory(memory[materialType.value], true);
      getCourseWareMemory(memoryNext[materialType.value], false);
      console.log("当前课时是-----", memory);
      console.log("下一课时是-----", memoryNext);

      console.log("获取到对应课时最受欢迎的课件是---", courseWareMemroy);
      console.log("获取到下一课时最受欢迎的课件是---", courseWareMemroyNext);

      materialMemory[materialType.value] = teachMaterialBook;

      // 上传至vuex 实时记录
      store.commit(MutationNames.CHANGE_TEACHMEMORY, {
        period: memory,
        nextPeriod: memoryNext,
        material: materialMemory,
        courseWare: courseWareMemroy,
        courseWareNext: courseWareMemroyNext,
      });
      // 写入本地存储
      await setTimeout(() => {
        handleWriteMemory();
      }, 200);
    };

    const handleWriteMemory = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        const TeachMemoryInfo = JSON.stringify({
          period: memory,
          nextPeriod: memoryNext,
          material: materialMemory,
          courseWare: courseWareMemroy,
          courseWareNext: courseWareMemroyNext,
          teacher: store.state.teacherId,
          school: store.state.schoolId,
        });
        // 向原生发送教学进度记忆信息
        console.log("向electron发送的JSON:");
        ipcRenderer.sendToHost("save-teach-memory", TeachMemoryInfo);
      }
    };

    watch(
      () => currentPage.value,
      () => {
        if (currentPage.value === 1) {
          leftBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
        }
      },
      { immediate: true }
    );
    watch(
      () => currentPage.value,
      () => {
        if (currentPage.value === totalPage.value) {
          rightBorderFlag.value = false;
        } else {
          rightBorderFlag.value = true;
        }
      }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          currentPage.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          currentPage.value === totalPage.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );

    return {
      isHighVersion,
      onSwiper,
      next,
      previous,
      firstLoading,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      getPrevData,
      getNextData,
      onSlideChange,
      closeCenterDialog,
      mySwiper,
      leftBorderFlag,
      reLoading,
      rightBorderFlag,
      middleResourcesEight,
      lastResourcesEight,
      isAndroid,
      url,
      materialName,
      defaultProps,
      treeData,
      pptLoading,
      pptLoadTxt,
      treeParams,
      treeCurrentNodeKey,
      dvLeftShow,
      centerDialogVisible,
      showResourceRef,
      resourcesEight,
      resourcesList,
      currentPage,
      totalPage,
      morePptShow,
      fullScreen,
      androidUserType,
      previousPptUrl,
      nextPptUrl,
      tree,
      bindIcon,
      handleNodeClick,
      handleNodeClickMore,
      handleOpenMoreResource,
      handleCardClick,
      handleCopyFromOfficial,
      handlePreviousPPT,
      handleNextPPT,
      handleChangePPT,
      enlarge,
      narrow,
      goBack,
      // handlePrevious,
      // handleNext,
      pptNowPlayMode,
      TabTypeMap,
      showIns,
      currentTab,
      noDataMsg,
      handleTabClick,
      init,
      resourcesEightTxt,
      middleResourcesEightTxt,
      lastResourcesEightTxt,
      handleLocalResource,
      handleLocalResourceStop,
      localResourceState,
      isReturnToPPT,
    };
  },
});
